import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import Modal from '@theme/components/utils/Modal'

export default {
  components: {
    AppImage,
    AppTitle,
    Modal,
  },
  props: {
    gift: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$refs.modal.hide()
    },
    show() {
      this.$refs.modal.show()
    },
  },
}
